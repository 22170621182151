import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Obfuscate from 'react-obfuscate';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import externalLink from '../../../assets/icons/interface/external-link.svg';
// assets
import leute from '../../../assets/images/leute.svg';
import { thomasHelbechtUrl, tobiasWaeldeUrl } from '../../../config/credit';
import { CATEGORIES } from '../../../data/categories';
import './footer.scss';
import { Widget } from './widget/Widget';
import { WidgetItem } from './widget/WidgetItem';

export const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <ReactSVG src={leute} className="bg-illustration" />

      <div className="container mx-auto z-50">
        <div className="widgets grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 ">
          <Widget title={t('common.nav.title')}>
            <WidgetItem title={t('common.nav.items.home')} to="/" />
            <WidgetItem title={t('common.nav.items.services')} to="/#leistungen" />
            <WidgetItem title={t('common.nav.items.ourwork')} to="/#referenzen" />
            <WidgetItem title={t('common.nav.items.contact')} to="/#kontakt" />
          </Widget>
          {CATEGORIES.map((category, i) => {
            return (
              <Widget key={i} to={category.path} title={category.title}>
                {category.services.map((service, j) => {
                  return (
                    <WidgetItem
                      key={j}
                      to={`${category.path}${service.path}`}
                      title={service.title}
                    />
                  );
                })}
              </Widget>
            );
          })}
        </div>
        <div className="legal">
          <Link
            to="/impressum"
            className="imprint hover:text-primary transition-colors duration-200"
          >
            {t('common.nav.items.imprint')}
          </Link>
          <Link className="hover:text-primary transition-colors duration-200" to="/datenschutz">
            {t('common.nav.items.privacypolicy')}
          </Link>
        </div>
        <div className="credit">
          Entwickelt von{' '}
          <span className="link">
            <Obfuscate
              href={thomasHelbechtUrl}
              obfuscateChildren={false}
              target="blank"
              rel="noreferrer"
            >
              <ReactSVG src={externalLink} className="icon" />
              Thomas Helbrecht
            </Obfuscate>
          </span>
          UND
          <span className="link">
            <Obfuscate
              href={tobiasWaeldeUrl}
              obfuscateChildren={false}
              target="blank"
              rel="noreferrer"
            >
              <ReactSVG src={externalLink} className="icon" />
              Tobias Wälde
            </Obfuscate>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
