import axios from 'axios';
// import isIp from 'is-ip';
// import os from 'os';

// const hostname = os.hostname();
const http = axios.create();

//#region Set base URL
// if (hostname === 'localhost' || hostname === '127.0.0.1' || hostname === '[::1]') {
//   http.defaults.baseURL = `http://${hostname}:3001/api`;
// } else if (isIp(hostname)) {
//   http.defaults.baseURL = `http://${hostname}:3001/api`;
// } else {
//   http.defaults.baseURL = `https://${hostname}/api`;
// }
//#endregion

// http.defaults.baseURL = 'https://frontity.tobiaswaelde.com/wp/wp-json';
http.defaults.baseURL = 'https://soon-werbung.de/wp/wp-json';

export default http;
