import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from '../../section/Section';
// images
import aufkleberThumbnail from './images/1.jpg';
import bannerThumbnail from './images/2.jpg';
import briefpapierThumbnail from './images/11.jpg';
import fahrzeugeThumbnail from './images/4.jpg';
import flyerThumbnail from './images/5.jpg';
import glasdekorThumbnail from './images/6.jpg';
import leuchtkastenThumbnail from './images/7.jpg';
import logosThumbnail from './images/8.jpg';
import posterThumbnail from './images/9.jpg';
import schaufensterThumbnail from './images/14.jpg';
import schilderThumbnail from './images/3.jpg';
import sonnenschutzfolieThumbnail from './images/12.jpg';
import tapeteThumbnail from './images/13.jpg';
import visitenkartenThumbnail from './images/15.jpg';
import wegleitsystemeThumbnail from './images/10.jpg';

import './index.scss';

const images = [
  aufkleberThumbnail,
  bannerThumbnail,
  briefpapierThumbnail,
  fahrzeugeThumbnail,
  flyerThumbnail,
  glasdekorThumbnail,
  leuchtkastenThumbnail,
  logosThumbnail,
  posterThumbnail,
  schaufensterThumbnail,
  schilderThumbnail,
  sonnenschutzfolieThumbnail,
  tapeteThumbnail,
  visitenkartenThumbnail,
  wegleitsystemeThumbnail,
];

export const OurWork: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="ourwork bg-black text-white px-8" id="referenzen">
      <div className="container mx-auto">
        <div className="items-wrapper">
          <Section
            title={t('pages.home.ourwork.title')}
            description={t('pages.home.ourwork.description')} //t('pages.home.ourwork.description')
            className="ourwork"
          >
            <section className="tiles tiles--rotated" id="grid2">
              <div className="tiles__wrap">
                <div
                  className="tiles__line"
                  data-scroll
                  data-scroll-speed="1"
                  data-scroll-target="#grid2"
                  data-scroll-direction="horizontal"
                >
                  {images.slice(0, 5).map((x, i) => (
                    <div key={i} className="tiles__line-img">
                      <img src={x} alt="" />
                    </div>
                  ))}
                  {/* <div className="tiles__line-img"></div>
                  <div className="tiles__line-img"></div>
                  <div className="tiles__line-img"></div>
                  <div className="tiles__line-img bg"></div>
                  <div className="tiles__line-img bg"></div>
                  <div className="tiles__line-img bg"></div> */}
                </div>
                <div
                  className="tiles__line"
                  data-scroll
                  data-scroll-speed="-1"
                  data-scroll-target="#grid2"
                  data-scroll-direction="horizontal"
                >
                  {images.slice(6, 11).map((x, i) => (
                    <div key={i} className="tiles__line-img">
                      <img src={x} alt="" />
                    </div>
                  ))}
                  {/* <div className="tiles__line-img"></div>
                  <div className="tiles__line-img bg"></div>
                  <div className="tiles__line-img bg"></div>
                  <div className="tiles__line-img"></div>
                  <div className="tiles__line-img bg"></div>
                  <div className="tiles__line-img bg"></div> */}
                </div>
                <div
                  className="tiles__line"
                  data-scroll
                  data-scroll-speed="1"
                  data-scroll-target="#grid2"
                  data-scroll-direction="horizontal"
                >
                  {[...images.slice(12, 15), ...images.slice(0, 2)].map((x, i) => (
                    <div key={i} className="tiles__line-img">
                      <img src={x} alt="" />
                    </div>
                  ))}
                  {/* <div className="tiles__line-img bg"></div>
                  <div className="tiles__line-img bg"></div>
                  <div className="tiles__line-img bg"></div>
                  <div className="tiles__line-img bg"></div>
                  <div className="tiles__line-img bg"></div>
                  <div className="tiles__line-img bg"></div> */}
                </div>
                <div
                  className="tiles__line"
                  data-scroll
                  data-scroll-speed="-1"
                  data-scroll-target="#grid2"
                  data-scroll-direction="horizontal"
                >
                  {images.slice(3, 8).map((x, i) => (
                    <div key={i} className="tiles__line-img">
                      <img src={x} alt="" />
                    </div>
                  ))}
                  {/* <div className="tiles__line-img bg"></div>
                  <div className="tiles__line-img bg"></div>
                  <div className="tiles__line-img bg"></div>
                  <div className="tiles__line-img bg"></div>
                  <div className="tiles__line-img bg"></div>
                  <div className="tiles__line-img"></div> */}
                </div>
                <div
                  className="tiles__line"
                  data-scroll
                  data-scroll-speed="1"
                  data-scroll-target="#grid2"
                  data-scroll-direction="horizontal"
                >
                  {images.slice(9, 14).map((x, i) => (
                    <div key={i} className="tiles__line-img">
                      <img src={x} alt="" />
                    </div>
                  ))}
                  {/* <div className="tiles__line-img"></div>
                  <div className="tiles__line-img bg"></div>
                  <div className="tiles__line-img bg"></div>
                  <div className="tiles__line-img bg"></div>
                  <div className="tiles__line-img"></div>
                  <div className="tiles__line-img"></div> */}
                </div>
              </div>
            </section>
          </Section>
        </div>
      </div>
    </div>
  );
};
