import { FC, useState } from 'react';
import { Analytics } from '../utils/analytics';
import { getCookies, setCookies } from '../utils/cookies';
import { createCtx } from './index';

interface CookiesContextType {
  mapboxEnabled: boolean;
  enableMapbox: () => void;

  recaptchaEnabled: boolean;
  enableRecaptcha: () => void;

  analyticsEnabled: boolean;
  enableAnalytics: () => void;
}

export const [useCookies, CtxProvider] = createCtx<CookiesContextType>();

export const CookiesProvider: FC = (props) => {
  const [mapboxEnabled, setMapboxEnabled] = useState<boolean>(false);
  const [recaptchaEnabled, setRecaptchaEnabled] = useState<boolean>(false);
  const [analyticsEnabled, setAnalyticsEnabled] = useState<boolean>(false);

  const cookieChecker = setInterval(() => {
    const cookies = getCookies();

    if (cookies.mapbox) enableMapbox();
    if (cookies.googleRecaptcha) enableRecaptcha();
    if (cookies.googleAnalytics) enableAnalytics();

    if (cookies.mapbox && cookies.googleRecaptcha && cookies.googleAnalytics) {
      clearInterval(cookieChecker);
    }
  }, 100);

  /**
   * Set required cookie for Mapbox and enable Mapbox
   */
  const enableMapbox = () => {
    setCookies({ mapbox: true });
    setMapboxEnabled(true);
  };

  /**
   * Set required cookie for Google ReCaptcha and enable Google ReCaptcha
   */
  const enableRecaptcha = () => {
    setCookies({ googleRecaptcha: true });
    setRecaptchaEnabled(true);
  };

  /**
   * Set required cookie for Google Analytics and enable Google Analytics
   */
  const enableAnalytics = () => {
    Analytics.initialize();
    setCookies({ googleAnalytics: true });
    setAnalyticsEnabled(true);
  };

  const contextValue: CookiesContextType = {
    mapboxEnabled,
    enableMapbox,
    recaptchaEnabled,
    enableRecaptcha,
    analyticsEnabled,
    enableAnalytics,
  };
  return <CtxProvider value={contextValue}>{props.children}</CtxProvider>;
};
