const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
  tablet: 640,
  laptop: 1024,
  desktop: 1280,
};

export function isInBreakpoint(
  breakpoint: keyof typeof breakpoints,
  direction: 'up' | 'down'
): boolean {
  const w = window.innerWidth;
  const bp = breakpoints[breakpoint];

  if (direction === 'up') {
    return w >= bp;
  } else {
    return w < bp;
  }
}
