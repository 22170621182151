import { FC } from 'react';
import { ReactSVG } from 'react-svg';

interface Props {
  icon: any;
  title: string;
  children: any;
}

export const ContactDetail: FC<Props> = (props) => {
  const { icon, title, children } = props;

  return (
    <div className="contact-detail w-56 flex flex-col justify-center items-center ">
      <div className="flex justify-center">
        <span className="inline-flex items-center justify-center p-5 rounded-md shadow-md bg-white">
          <ReactSVG src={icon} className="h-7 w-7 text-primary" />
        </span>
      </div>

      <h3 className="mt-6 text-white mb-3 font-semibold text-base">{title}</h3>
      <div className="value text-white leading-loose">{children}</div>
    </div>
  );
};
