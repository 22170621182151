import { createBrowserHistory } from 'history';
import 'photoswipe/dist/default-skin/default-skin.css';
// photoswipe
import 'photoswipe/dist/photoswipe.css';
import { FC } from 'react';
import { Redirect, Route, Router } from 'react-router';
import { AnimatedSwitch } from 'react-router-transition';
import { ScrollHint } from './components/common/scrollhint/ScrollHint';
import { ScrollToTopButton } from './components/common/scrolltop/ScrollToTopButton';
import { Footer } from './components/layout/footer/Footer';
// Layout
import { Navbar } from './components/layout/navbar/Navbar';
import { PhotoswipeContainer } from './components/photoswipe/PhotoswipeContainer';
import ScrollToTopOnRouteChange from './components/util/ScrollToTopOnRouteChange';
import { LocomotiveProvider, ScrollableContent } from './contexts/locomotive';
import { Category } from './pages/category/Category';
import { NotFound } from './pages/error/NotFound';
// Pages
import { Home } from './pages/home/Home';
import { Imprint } from './pages/imprint/Imprint';
import { PrivacyPolicy } from './pages/privacy-policy/PrivacyPolicy';
import { Service } from './pages/service/Service';
import { AnalyticsWrapper } from './utils/analytics';

/**
 * The default browser history of the app
 */
export const history = createBrowserHistory({});

/**
 * The default app router
 */
export const AppRouter: FC = () => {
  return (
    <Router history={history}>
      <AnalyticsWrapper>
        <LocomotiveProvider>
          <ScrollToTopOnRouteChange />
          <ScrollToTopButton />
          <ScrollHint />

          <Navbar />

          <ScrollableContent>
            <div className="main-content flex-1" id="main-content">
              <AnimatedSwitch
                atEnter={{ opacity: 0 }}
                atLeave={{ opacity: 1 }}
                atActive={{ opacity: 1 }}
                className="switch-wrapper"
              >
                <Route exact path="/" component={Home} />

                <Route exact path="/impressum" component={Imprint} />
                <Route exact path="/datenschutz" component={PrivacyPolicy} />

                <Route exact path="/404" component={NotFound} />

                <Route exact path="/:category" component={Category} />
                <Route exact path="/:category/:service" component={Service} />

                <Redirect to="/404" />
              </AnimatedSwitch>
            </div>
            <Footer />
          </ScrollableContent>
        </LocomotiveProvider>
      </AnalyticsWrapper>

      <PhotoswipeContainer />
    </Router>
  );
};
