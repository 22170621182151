import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router';
import { ReactSVG } from 'react-svg';
import { Breadcrumb } from '../../components/common/breadcrumb/Breadcrumb';
import { CallToAction } from '../../components/common/cta/CallToAction';
import { Paragraph } from '../../components/common/paragraph/Paragraph';
import { Skeleton } from '../../components/common/skeleton/Skeleton';
import { Page } from '../../components/layout/page/page';
import { PhotoswipeWrapper } from '../../components/photoswipe/PhotoswipeWrapper';
import { useBackend } from '../../contexts/backend';
import { CATEGORIES } from '../../data/categories';
import './service.scss';
import { Thumbnail } from './Thumbnail';

const TemplateSkeleton: FC = () => {
  return (
    <div className="products">
      <Skeleton.Box className="w-full aspect-w-4 aspect-h-3" />
      <Skeleton.Box className="w-full aspect-w-4 aspect-h-3" />
      <Skeleton.Box className="w-full aspect-w-4 aspect-h-3" />
      <Skeleton.Box className="w-full aspect-w-4 aspect-h-3" />
      <Skeleton.Box className="w-full aspect-w-4 aspect-h-3" />
      <Skeleton.Box className="w-full aspect-w-4 aspect-h-3" />
      <Skeleton.Box className="w-full aspect-w-4 aspect-h-3" />
      <Skeleton.Box className="w-full aspect-w-4 aspect-h-3" />
    </div>
  );
};

type Params = {
  category: string;
  service: string;
};
export const Service: FC = () => {
  const params = useParams<Params>();
  const { loading, currentMedia, fetchMedia } = useBackend();
  const [mediaId, setMediaId] = useState<number>(undefined!);

  const [photoswipeOpen, setPhotoswipeOpen] = useState<boolean>(false);
  const [photoswipeIndex, setPhotoswipeIndex] = useState<number>(0);

  //#region reset media ID on params change
  useEffect(() => {
    setMediaId(undefined!);
  }, [params]);
  //#endregion

  //#region fetch media
  useEffect(() => {
    if (!mediaId) return;
    fetchMedia(mediaId);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaId]);
  //#endregion

  const { t } = useTranslation();
  //#region find category
  const category = CATEGORIES.find((c) => c.path === `/${params.category}`);
  if (!category) {
    return <Redirect to="/404" />;
  }
  //#endregion
  //#region find service
  const service = category.services.find((s) => s.path === `/${params.service}`);
  if (!service) {
    return <Redirect to={category.path} />;
  }
  if (!mediaId) setMediaId(service.mediaCategoryId);
  //#endregion

  //#region SEO
  const metaTitle = service.title + t('seo:delimiter') + category.title + t('seo:suffix');
  const metaDescription = t(
    'seo:pages.categories.' +
      category.path.replace('/', '') +
      '.services.' +
      service.path.replace('/', '')
  );
  const metaImg =
    currentMedia.length === 0 ? undefined : currentMedia[0].sizes.medium_large.source_url;
  //#endregion

  return (
    <Page title={metaTitle} description={metaDescription} ogImage={metaImg} className="">
      <Breadcrumb>
        <Breadcrumb.Home />
        <Breadcrumb.Item title={category.title} to={category.path} />
        <Breadcrumb.Item title={service.title} to={`${category.path}${service.path}`} active />
      </Breadcrumb>

      <div className="service px-8">
        <div className="container mx-auto">
          <div className="header">
            <div className="title">
              <div className="icon">
                <ReactSVG src={service.icon} className="h-5 w-5" />
              </div>
              <h2 className="text ">{service.title}</h2>
            </div>
            <Paragraph.Small customClassName="description" leadingLoose left html>
              {service.description}
            </Paragraph.Small>
          </div>

          {(loading || currentMedia.length === 0) && <TemplateSkeleton />}

          {!loading && currentMedia.length > 0 && (
            <div className="products">
              {currentMedia.map((media, i) => {
                return (
                  <Thumbnail
                    key={i}
                    img={media.sizes.medium_large?.source_url || media.sizes.full.source_url}
                    onClick={() => {
                      setPhotoswipeIndex(i);
                      setPhotoswipeOpen(true);
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>

        {currentMedia.length > 0 && (
          <PhotoswipeWrapper
            items={currentMedia.map((x) => ({
              src: x.sizes.full.source_url,
              w: x.sizes.full.width,
              h: x.sizes.full.height,
            }))}
            isOpen={photoswipeOpen}
            index={photoswipeIndex}
            onClose={() => setPhotoswipeOpen(false)}
          />
        )}
      </div>
      <div className="mt-24">
        <CallToAction />
      </div>
    </Page>
  );
};
