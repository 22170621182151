import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import leute from '../../../assets/images/leute.svg';
import logo from '../../../assets/images/logo.svg';
import spinner from './spinner.svg';

import './splashscreen.scss';

export const SplashScreen: FC = () => {
  return (
    <div id="splash-screen" className="splash-screen">
      <div className="content">
        <ReactSVG src={spinner} className="spinner" />
        <ReactSVG src={logo} className="logo" />
      </div>
      <ReactSVG src={leute} className="illustration" />
    </div>
  );
};
