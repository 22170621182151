import { FC, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useLocomotive } from '../../contexts/locomotive';

const ScrollToTopOnRouteChange: FC<RouteComponentProps> = (props) => {
  const { scroll } = useLocomotive();

  //#region detect route change
  useEffect(() => {
    if (!scroll) {
      window.scrollTo(0, 0);
      return;
    }

    scroll.scrollTo('top', { duration: 0 });
  }, [props.location.pathname, scroll]);
  //#endregion

  return null;
};

export default withRouter(ScrollToTopOnRouteChange);
