import clsx from 'clsx';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
// assets
import arrowRightIcon from '../../../assets/icons/interface/arrow-right.svg';
import { history } from '../../../router';

type Props = {
  title: string;
  to: string;
  icon: any;
  onClick?: Function;
};

export const NavbarSubItem: FC<Props> = (props) => {
  const { title, to, icon, onClick } = props;
  // TODO: FIX HOVER BEHAVIOUR OF THIRD MENU(WRONG WIDTH ON HOVER!)
  const [hover, setHover] = useState<boolean>(false);

  return (
    <li
      className="navbar-sub-item"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={(e) => {
        e.stopPropagation();
        history.push(to);
        // Scroll up within mobile menu
        document.getElementById('menu')?.scrollTo(0, 0);
        if (onClick) onClick();
      }}
    >
      <Link
        to={to}
        className="transition-colors py-3 flex flex-row content-center items-center rounded-md xl:px-3 xl:py-3 hover:bg-black"
      >
        <div className="bg-primary rounded-full w-10 h-10 flex justify-center items-center content-center">
          <ReactSVG src={icon} className="w-4 h-4" />
        </div>
        <span className="text-sm ml-4 pr-2 flex-1 whitespace-nowrap">{title}</span>

        <div className={clsx({ invisible: !hover })}>
          <ReactSVG src={arrowRightIcon} className="tablericon w-2.5 h-2.5" />
        </div>
      </Link>
    </li>
  );
};
