import clsx from 'clsx';
import React, { FC, HTMLProps } from 'react';
import { ReactSVG } from 'react-svg';

type Variants = 'default' | 'secondary' | 'success';

interface Props extends HTMLProps<HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset';
  icon: string | React.ReactNode;
  variant?: Variants;
}

export const IconButton: FC<Props> = (props) => {
  const { icon, children, className, variant, ...buttonProps } = props;

  return (
    <button
      {...buttonProps}
      className={clsx(
        'inline-flex flex-row items-center',
        'px-6 py-3.5 rounded-sm text-base font-semibold',
        'cursor-pointer transition-all focus:outline-none',
        {
          'bg-primary text-white hover:bg-btn-hover': !variant || variant === 'default',
          'bg-white text-primary': variant === 'secondary',
          'bg-success text-white': variant === 'success',
          'cursor-not-allowed bg-p-secondary text-white hover:bg-p-secondary hover:text-white':
            buttonProps.disabled,
        }
      )}
    >
      <span data-scroll>
        {typeof icon === 'string' ? <ReactSVG src={icon} className={clsx('h-5 w-5')} /> : icon}
      </span>
      <span className="text-sm ml-6 pl-2 pr-3 text-current">{props.children}</span>
    </button>
  );
};
