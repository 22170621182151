import { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  to?: string;
}
export const Widget: FC<Props> = (props) => {
  const { title, to, children } = props;

  return (
    <div className="widget flex-1 mb-8 xl:mb-0">
      <h2 className="font-black text-base uppercase mb-4">
        {to && (
          <Link to={to} className="hover:text-primary transition-colors duration-200">
            {title}
          </Link>
        )}
        {!to && title}
      </h2>
      <ul className="flex flex-col items-start">{children}</ul>
    </div>
  );
};
