import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router';
import { Link } from 'react-router-dom';
// assets
import messagesIcon from '../../assets/icons/interface/messages.svg';
import { Breadcrumb } from '../../components/common/breadcrumb/Breadcrumb';
import { CallToAction } from '../../components/common/cta/CallToAction';
import { IconButton } from '../../components/common/iconbutton/IconButton';
import { Paragraph } from '../../components/common/paragraph/Paragraph';
import { Page } from '../../components/layout/page/page';
import { CATEGORIES } from '../../data/categories';
import { Service } from './service/Service';

import './category.scss';

type Params = {
  category: string;
};
export const Category: FC = () => {
  const { t } = useTranslation();
  const params = useParams<Params>();

  //#region find category
  const category = CATEGORIES.find((c) => c.path === `/${params.category}`);
  if (!category) {
    return <Redirect to="/404" />;
  }
  //#endregion

  //#region SEO
  const metaTitle = category.title + t('seo:suffix');
  const metaDescription = t(`seo:pages.categories.${category.path.replace('/', '')}.description`);
  //#endregion

  return (
    <Page title={metaTitle} description={metaDescription} className="categories">
      <Breadcrumb>
        <Breadcrumb.Home />
        <Breadcrumb.Item title={category.title} to={category.path} active />
      </Breadcrumb>

      <div className="header">
        <div className="container mx-auto">
          <h2 className="title">{category.title}</h2>

          {category.description && (
            <Paragraph.Small customClassName="description" html>
              {category.description}
            </Paragraph.Small>
          )}
          <Link to="/#kontakt">
            <IconButton icon={messagesIcon}>{t('common.contact')}</IconButton>
          </Link>
        </div>
      </div>

      {category.services.map((service, i) => {
        return (
          <Service
            key={i}
            title={service.title}
            image={service.img}
            icon={service.icon}
            description={service.description}
            url={`${category.path}${service.path}`}
            light={(category.services.length - i) % 2 === 0} // ensure last service to be dark
          />
        );
      })}

      <CallToAction />
    </Page>
  );
};
