import { FC } from 'react';
import Paragraph from '../../../components/common/paragraph/Paragraph';

import './index.scss';

interface Props {
  title: string;
  description: string;
  children: any;
  className: string;
  id?: string;
}

export const Section: FC<Props> = (props) => {
  const { title, description, children, className, id } = props;

  return (
    <div className={'lp-section ' + className} id={id}>
      <div className="lp-header">
        <h2 className="title">{title}</h2>
        <Paragraph.Small centered>{description}</Paragraph.Small>
      </div>
      <div className="lp-content">{children}</div>
    </div>
  );
};
