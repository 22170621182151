/**
 * The current Node.js environment
 */
export const NODE_ENV = String(process.env.NODE_ENV)?.trim();

/** Mapbox access token */
export const MAPBOX_ACCESS_TOKEN = String(process.env.REACT_APP_MAPBOX_ACCESS_TOKEN).trim();

/**
 * Google Analytics tracking code
 */
export const GOOGLE_ANALYTICS_TRACKING_CODE = String(
  process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE
)?.trim();

/**
 * Google reCaptcha V3 public key
 */
export const GOOGLE_RECAPTCHAV3_PUBLIC_KEY = String(
  process.env.REACT_APP_GOOGLE_RECAPTCHAV3_PUBLIC_KEY
)?.trim();
