import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { ReactSVG } from 'react-svg';
import messagesIcon from '../../../../assets/icons/interface/messages.svg';
// assets
import leute from '../../../../assets/images/leute.svg';
import { IconButton } from '../../../../components/common/iconbutton/IconButton';
import { useLocomotive } from '../../../../contexts/locomotive';
import { history } from '../../../../router';
import './index.scss';

export const Landing: FC = () => {
  const { t } = useTranslation();
  const locomotive = useLocomotive();
  const location = useLocation();

  //#region Scroll to contact
  const scrollTo = (id: string) => {
    setTimeout(() => {
      locomotive?.scroll?.scrollTo(document.getElementById(id)?.offsetTop);
    }, 250);
  };
  //#endregion

  return (
    <div className="landing  bg-lp">
      <div className="content ">
        <div className="container mx-auto">
          <div className="text">
            <h1 className="font-extrabold text-4xl leading-loose text-black ">
              {t('pages.home.landing.title.line1')}
              <br />
              {t('pages.home.landing.title.line2')}
              <br />
              {t('pages.home.landing.title.line3')}
            </h1>
            <div className="font-semibold text-2xl mb-4" style={{ color: '#303030' }}>
              {t('pages.home.landing.title.caption')}
            </div>
          </div>

          <IconButton
            icon={messagesIcon}
            onClick={() => {
              history.push('/#kontakt');
              if (location.hash === '#kontakt') {
                scrollTo('kontakt');
              }
            }}
          >
            {t('common.contact')}
          </IconButton>
        </div>
      </div>
      <div className="illustration relative -mb-0.5">
        <ReactSVG src={leute} className="max-w-full w-full text-black" />
      </div>
    </div>
  );
};
