/**
 * Convert rem value to px value
 * @param {number} rem The value in [rem]to convert
 * @returns {number} The value in [px]
 */
export function convertRemToPx(rem: number): number {
  const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
  return rem * fontSize;
}

/**
 * Limit value between lower and upper boundary
 * @param {number} value The value to limit
 * @param {number} min The lower boundary; default: `0`
 * @param {number} max The upper boundary; default: `1`
 * @returns {number} The limited value
 */
export function limitMinMax(value: number, min?: number, max?: number): number {
  min = min || 0;
  max = max || 1;

  return Math.min(Math.max(value, min), max);
}
