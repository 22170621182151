import clsx from 'clsx';
import { ComponentProps, FC } from 'react';

const H1: FC<ComponentProps<'h1' | 'h2' | 'h3'>> = () => {
  return (
    <div className={clsx('max-w-xs mb-3 h-10', 'bg-secondary text-secondary rounded-md')}></div>
  );
};
const H2: FC<ComponentProps<'h1' | 'h2' | 'h3'>> = () => {
  return (
    <div className={clsx('max-w-sm mb-3 h-8', 'bg-secondary text-secondary rounded-md')}></div>
  );
};
const H3: FC<ComponentProps<'h1' | 'h2' | 'h3'>> = () => {
  return (
    <div className={clsx('max-w-md mb-3 h-7', 'bg-secondary text-secondary rounded-md')}></div>
  );
};

const Line: FC = () => {
  return <div className="w-full rounded-md bg-secondary h-6 mb-2"></div>;
};

const Box: FC<{ className?: string }> = (props) => {
  return (
    <div className={clsx('bg-secondary text-secondary rounded-md', props.className || '')}></div>
  );
};

const Spacer: FC<{ height: number }> = (props) => {
  return <div className={`block w-full h-${props.height}`}></div>;
};

interface Type {
  H1: typeof H1;
  H2: typeof H2;
  H3: typeof H3;
  Line: typeof Line;
  Box: typeof Box;
  Spacer: typeof Spacer;
}
export const Skeleton: FC<{}> & Type = () => {
  return null;
};

Skeleton.H1 = H1;
Skeleton.H2 = H2;
Skeleton.H3 = H3;
Skeleton.Line = Line;
Skeleton.Box = Box;
Skeleton.Spacer = Spacer;
