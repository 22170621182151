import clsx from 'clsx';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { ReactSVG } from 'react-svg';
// assets
import mouseIcon from '../../../assets/icons/interface/mouse-smart.svg';
import { useLocomotive } from '../../../contexts/locomotive';
import { convertRemToPx } from '../../../utils/math';
import "./scrollhint.scss";

export const ScrollHint: FC = () => {
  const { t } = useTranslation();
  const locomotive = useLocomotive();
  const container = useRef<HTMLDivElement>(null);

  const firstContentElement = document.getElementById('leistungen');

  const [visible, setVisible] = useState<boolean>(true);

  // TODO: Adjust the visibilty value, especially for mobile devices!
  useEffect(() => {
    if (firstContentElement && container && container.current) {
      const HINT_VISIBLE_AT = Math.abs(
        firstContentElement.offsetTop - (document.documentElement.clientHeight - convertRemToPx(4))
      );
      if (locomotive.y < HINT_VISIBLE_AT) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [firstContentElement, locomotive.y]);

  //#region only show on /
  const location = useLocation();
  useEffect(() => {
    setVisible(true);
  }, [location.pathname]);

  if (location.pathname !== '/') {
    return null;
  }
  //#endregion

  return (
    <div
      className={clsx(
        'px-8 bottom-8 text-xs w-full fixed flex-col justify-end z-scrollhint pointer-events-none',
        'lg:pb-12 lg:text-sm',
        'transition-all duration-300 ease-linear',
        {
          'opacity-100': visible,
          'opacity-0': !visible,
        }
      )}
      ref={container}
    >
      <div className="scrollhint container mx-auto flex flex-row items-center z-scrollhint text-white">
        <div className="flex flex-row items-center bg-secondary py-2 px-4 rounded-md">
          <ReactSVG src={mouseIcon} className="tablericon w-5 h-5 animate-lowbounce" />
          <div className="ml-4">{t('common.scrollhint.text')}</div>
        </div>
      </div>
    </div>
  );
};
