import clsx from 'clsx';
import { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
// assets
import maximizeIcon from '../../assets/icons/interface/maximize.svg';
import './thumbnail.scss';

type Props = {
  img: string;
  onClick: MouseEventHandler<HTMLDivElement>;
};
export const Thumbnail: FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'thumbnail',
        'relative rounded-lg cursor-pointer aspect-w-4 aspect-h-3 overflow-hidden'
      )}
      onClick={props.onClick}
    >
      <img src={props.img} alt="" className="w-full object-cover rounded-lg transition transform" />
      <div
        className={clsx(
          'w-full flex flex-row absolute bottom-0 p-6 justify-center items-end rounded-b-lg',
          'text-white bg-gradient-to-t from-thumbnail to-transparent',
          'md:justify-start'
        )}
      >
        <ReactSVG src={maximizeIcon} className="w-5 h-5" />
        <div className="ml-4 text-sm font-semibold">{t('pages.service.thumbnail.view')}</div>
      </div>
    </div>
  );
};
