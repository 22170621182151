import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import Button from '../../../components/common/button/Button';
import Paragraph from '../../../components/common/paragraph/Paragraph';
import './service.scss';

interface Props {
  title: string;
  icon: any;
  description?: string;
  url: string;
  image: any;
  light?: boolean;
}

export const Service: FC<Props> = (props) => {
  const { title, icon, description, url, image, light } = props;
  const { t } = useTranslation();

  return (
    <div
      className={clsx('service', {
        'bg-lightblack': light,
        'bg-black': !light,
      })}
    >
      <div className="container">
        <div className="lg:flex-1 w-full lg:w-auto">
          <div className="w-full  aspect-w-14 aspect-h-9">
            <img src={image} alt="service" className="rounded-md object-cover" />
          </div>
        </div>

        <div className="content">
          <div className="title">
            <div className="tablericon">
              <ReactSVG src={icon} className="h-5 w-5" style={{ color: 'white' }} />
            </div>
            <h2 className="text">{title}</h2>
          </div>

          {description && (
            <Paragraph.Small customClassName="description" html>
              {description}
            </Paragraph.Small>
          )}

          <div className="button-container">
            <Link to={url}>
              <Button>{t('pages.category.service.more')}</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
