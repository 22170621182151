import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import PhotoSwipe from 'photoswipe/dist/photoswipe.js';
import React, { useEffect } from 'react';

export const PhotoswipeWrapper = (props) => {
  useEffect(() => {
    const options = {
      index: props.index || 0,
      closeOnScroll: false,
      history: false,
      escKey: true,
      shareButtons: [],
      shareEl: false,
    };

    const photoSwipe = new PhotoSwipe(
      document.getElementById('photoswipe'),
      PhotoSwipeUI_Default,
      props.items,
      options
    );

    if (photoSwipe) {
      if (props.isOpen) {
        photoSwipe.init();

        photoSwipe.listen('destroy', () => {
          props.onClose();
        });

        photoSwipe.listen('close', () => {
          props.onClose();
        });
      }
      if (!props.isOpen) {
        props.onClose();
      }
    }
  }, [props]);
  // TODO: Move all of this to service.tsx
  return <div className="p"></div>;
};
