import architectureWindow1 from '../assets/icons/content/architecture-window-1.svg';
import billboard from '../assets/icons/content/billboard.svg';
import carWagon1 from '../assets/icons/content/car-wagon-1.svg';
import constructionPaint from '../assets/icons/content/construction-paint.svg';
import designDraw from '../assets/icons/content/design-draw.svg';
import designToolQuill2 from '../assets/icons/content/design-tool-quill-2.svg';
import emailActionReadDocument from '../assets/icons/content/email-action-read-document.svg';
import envelopeLetter from '../assets/icons/content/envelope-letter.svg';
import flyerTaken from '../assets/icons/content/flyer-taken.svg';
import lightBulb from '../assets/icons/content/lightbulb.svg';
import navigationMenuHorizontal1Alternate from '../assets/icons/content/navigation-menu-horizontal-1-alternate.svg';
import retouchSticker from '../assets/icons/content/retouch-sticker.svg';
import shop1 from '../assets/icons/content/shop-1.svg';
// icons
import shoppingStoreSignage2 from '../assets/icons/content/shopping-store-signange-2.svg';
import stampsImageAlternate from '../assets/icons/content/stamps-image-alternate.svg';
import travelPlacesStreetSign from '../assets/icons/content/travel-places-street-sign.svg';
import weatherSun from '../assets/icons/content/weather-sun.svg';
// thumbnails
import aufkleberThumbnail from '../assets/images/categories/aufkleber.jpg';
import bannerThumbnail from '../assets/images/categories/banner.jpg';
import briefpapierThumbnail from '../assets/images/categories/briefpapier.jpg';
import fahrzeugeThumbnail from '../assets/images/categories/fahrzeuge.jpg';
import flyerThumbnail from '../assets/images/categories/flyer.jpg';
import glasdekorThumbnail from '../assets/images/categories/glasdekor.jpeg';
import leuchtkastenThumbnail from '../assets/images/categories/leuchtkasten.jpg';
import logosThumbnail from '../assets/images/categories/logos.jpg';
import posterThumbnail from '../assets/images/categories/poster.jpg';
import schaufensterThumbnail from '../assets/images/categories/schaufenster.jpg';
import schilderThumbnail from '../assets/images/categories/schilder.jpg';
import sonnenschutzfolieThumbnail from '../assets/images/categories/sonnenschutzfolie.jpg';
import tapeteThumbnail from '../assets/images/categories/tapete.jpg';
import visitenkartenThumbnail from '../assets/images/categories/visitenkarten.jpg';
import wegleitsystemeThumbnail from '../assets/images/categories/wegleitsysteme.jpg';
import { Category } from '../types/category';

/**
 * Contains the IDs for the media categories
 */
export const CATEGORIES: Category[] = [
  //#region Werbetechnik
  {
    path: '/werbetechnik',
    icon: billboard,
    title: 'Werbetechnik',
    shortDescription:
      'Im Bereich der Werbetechnik bieten wir Ihnen diverse Leistungen an. Unter anderem Fahrzeug- und Schaufensterbeschriftung, Wegleitsysteme, Schilder, Leuchtkästen und vieles mehr.',
    description: `Im Bereich der Werbetechnik bieten wir Ihnen diverse Leistungen an. Unter anderem Fahrzeug- und Schaufensterbeschriftung, Wegleitsysteme, Schilder, Leuchtkästen und vieles mehr.`,
    mediaCategoryId: 13, // 18
    services: [
      {
        path: '/fahrzeuge',
        icon: carWagon1,
        title: 'Fahrzeuge',
        description: `Ihr Fahrzeug bietet eine große und mobile Werbefläche. Wir beschriften sämtliche Fahrzeuge von LKWs, Transportern bis PKWs. Ganz nach Ihren Wünschen bieten wir großflächige Beklebung mit 3D-Foliendrucken oder einfachen Folienbeschriftungen an.`,
        img: fahrzeugeThumbnail,
        mediaCategoryId: 15, // 7
      },
      {
        path: '/schaufenster',
        icon: shop1,
        title: 'Schaufenster',
        description: `Wir beschriften ihr Schaufenster ganz nach ihrer Vorstellung und unterstützen Sie bei Bedarf auch gerne bei der Gestaltung.`,
        img: schaufensterThumbnail,
        mediaCategoryId: 17, // 8
      },
      {
        path: '/schilder',
        icon: shoppingStoreSignage2,
        title: 'Schilder',
        description: `Schilder nach ihren Wünschen in sämtlichen Größen und Materialien von Kunststoff über Acrylglas bis Aluverbund. `,
        img: schilderThumbnail,
        mediaCategoryId: 18, // 19
      },
      {
        path: '/leuchtkaesten',
        icon: lightBulb,
        title: 'Leuchtkästen',
        description: `Wir bieten Leuchtkästen, einzelne Leuchtbuchstaben oder Leuchtpylone. Ganz nach ihren Bedürfnissen in verschiedensten Formaten und Materialien.`,
        img: leuchtkastenThumbnail,
        mediaCategoryId: 16, // 20
      },
      {
        path: '/banner',
        icon: billboard,
        title: 'Banner',
        description: `Spanntransparente, Fahnen und Banner. Sie wählen nach Belieben das Material, das Format und das Rahmensystem.`,
        img: bannerThumbnail,
        mediaCategoryId: 14, // 21
      },
      {
        path: '/wegleitsysteme',
        icon: travelPlacesStreetSign,
        title: 'Wegleitsysteme',
        description: `Wir helfen gerne mit einer klaren und übersichtlichen Beschilderung für eine schnelle und leicht verständliche Orientierung in ihrem Hause zu sorgen. `,
        img: wegleitsystemeThumbnail,
        mediaCategoryId: 19, // 22
      },
    ],
  },
  //#endregion
  //#region Gestaltung
  {
    path: '/gestaltung',
    icon: designToolQuill2,
    title: 'Gestaltung',
    shortDescription:
      'Wir kümmern uns um den gesamten Auftritt ihres Unternehmens, vom Logo über die Visitenkarte bis zum Außenauftritt gestalten wir gerne alles für Sie.',
    description: `Wir kümmern uns um den gesamten Auftritt ihres Unternehmens, vom Logo über die Visitenkarte bis zum Außenauftritt gestalten wir gerne alles für Sie.`,
    mediaCategoryId: 2, // 24
    services: [
      {
        path: '/logos',
        icon: designDraw,
        title: 'Logos',
        description: `Wir arbeiten bei der Gestaltung ganz nach ihren Wünschen und setzen bei unserem Design auf Minimalismus und Einfachheit. `,
        img: logosThumbnail,
        mediaCategoryId: 5, // 25
      },
      {
        path: '/briefpapier',
        icon: emailActionReadDocument,
        title: 'Briefpapier',
        description: ``,
        img: briefpapierThumbnail,
        mediaCategoryId: 3, // 26
      },
      {
        path: '/visitenkarten',
        icon: envelopeLetter,
        title: 'Visitenkarten',
        description: ``,
        img: visitenkartenThumbnail,
        mediaCategoryId: 7, // 27
      },
      {
        path: '/flyer',
        icon: flyerTaken,
        title: 'Flyer',
        description: `Flyer in sämtlichen Formaten, gestaltet nach Ihren Wünschen.`,
        img: flyerThumbnail,
        mediaCategoryId: 4, // 28
      },
      {
        path: '/poster',
        icon: stampsImageAlternate,
        title: 'Poster',
        description: `Wir helfen Ihnen bei individuell Gestaltung von Plakaten in verschiedenste Materialien, wie z.B. Papier oder Hohlkammerplatten gerne weiter.`,
        img: posterThumbnail,
        mediaCategoryId: 6, // 29
      },
    ],
  },
  //#endregion
  //#region Sonstiges
  {
    path: '/sonstiges',
    icon: navigationMenuHorizontal1Alternate,
    title: 'Sonstiges',
    shortDescription:
      'Auch bei der Innengestaltung ihres Geschäfts oder Privathauses mit individuell gestalteten Tapeten und Wandbilder, Sichtschutz oder Wärmeschutz, helfen wir Ihnen gerne weiter.',
    description: `Auch bei der Innengestaltung ihres Geschäfts oder Privathauses mit individuell gestalteten Tapeten und Wandbilder, Sichtschutz oder Wärmeschutz, helfen wir Ihnen gerne weiter.`,
    mediaCategoryId: 8, // 30
    services: [
      {
        path: '/tapete-wandbilder',
        icon: constructionPaint,
        title: 'Tapete / Wandbilder',
        description: `Wir drucken Ihnen individuelle Tapeten mit Ihrem Wunschmotiv in Ihrem Wunschformat. Zudem können wir ihnen auch Folien für die Wand und Schablonen zum Streichen anbieten.`,
        img: tapeteThumbnail,
        mediaCategoryId: 12, // 31
      },
      {
        path: '/glasdekor',
        icon: architectureWindow1,
        title: 'Glasdekor',
        description: `Ob als Sichtschutz oder zur Dekoration wir helfen Ihnen mit individuell gestalteter Glasdekorfolie für Fenster, Türen und andere Glasscheiben gerne weiter.`,
        img: glasdekorThumbnail,
        mediaCategoryId: 10, // 32
      },
      {
        path: '/sonnenschutzfolie-flachglas',
        icon: weatherSun,
        title: 'Sonnenschutzfolie Flachglas',
        description: `Wir bieten Sonnenschutzfolie für Wärmeschutz und einen gewissen Sichtschutz durch die Verspiegelung der Außenseite.`,
        img: sonnenschutzfolieThumbnail,
        mediaCategoryId: 11, // 33
      },
      {
        path: '/aufkleber',
        icon: retouchSticker,
        title: 'Aufkleber',
        description: `Wir drucken Ihnen gerne Ihre individuell gestalteten Aufkleber in Ihrem Wunschformat.`,
        img: aufkleberThumbnail,
        mediaCategoryId: 9, // 34
      },
    ],
  },
  //#endregion
];
