import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import arrowDown1 from '../../../assets/icons/interface/arrow-down-1.svg';
import { history } from '../../../router';
import { isInBreakpoint } from '../../util/isInBreakpoint';

interface Props {
  title: string;
  to?: string;
  children?: JSX.Element[];
  onClick?: Function;
}

export const NavbarItem: FC<Props> = (props) => {
  const { title, to, children, onClick } = props;

  const [isMobileNav, setIsMobileNav] = useState<boolean>(isInBreakpoint('lg', 'down'));
  const [submenuOpen, setSubmenuOpen] = useState<boolean>(false);

  //#region check mobile nav
  useEffect(() => {
    const onResize = () => {
      setIsMobileNav(isInBreakpoint('xl', 'down'));
    };

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);
  //#endregion

  history.listen(() => {
    setSubmenuOpen(false);
  });
  return (
    <li
      className="navbar-item"
      onMouseEnter={() => {
        if (!isMobileNav) {
          setSubmenuOpen(true);
        }
      }}
      onMouseLeave={() => {
        if (!isMobileNav) {
          setSubmenuOpen(false);
        }
      }}
      onClick={(e) => {
        // Scroll up within mobile menu
        if (!isMobileNav) {
          document.getElementById('menu')?.scrollTo(0, 0);

          if (to) history.push(to);
          if (onClick) {
            e.stopPropagation();
            onClick();
          }
        }
      }}
    >
      <div className="label flex items-center">
        {!children && (
          <span
            className="text-sm"
            onClick={(e) => {
              // Scroll up within mobile menu
              if (isMobileNav) {
                document.getElementById('menu')?.scrollTo(0, 0);

                if (to) history.push(to);
                if (onClick) {
                  e.stopPropagation();
                  onClick();
                }
              }
            }}
          >
            {title}
          </span>
        )}
        {children && (
          <div className="item">
            <span
              className="text-sm"
              onClick={(e) => {
                // Scroll up within mobile menu
                if (isMobileNav) {
                  document.getElementById('menu')?.scrollTo(0, 0);

                  if (to) history.push(to);
                  if (onClick) {
                    e.stopPropagation();
                    onClick();
                  }
                }
              }}
            >
              {title}
            </span>
            <div
              className="icon"
              onClick={(e) => {
                e.stopPropagation();
                setSubmenuOpen(!submenuOpen);
              }}
            >
              <ReactSVG
                src={arrowDown1}
                className={clsx('svg', {
                  'transform -rotate-180': submenuOpen,
                })}
              />
            </div>
          </div>
        )}
      </div>

      {children && (
        <Transition
          show={submenuOpen}
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="secondary-menu-transition-wrapper"
        >
          <div
            className="secondary-menu-wrapper cursor-default"
            onClick={(e) => e.stopPropagation()}
          >
            <div>
              <ul className="secondary-menu" onClick={(e) => e.stopPropagation()}>
                {children}
              </ul>
            </div>
          </div>
        </Transition>
      )}
    </li>
  );
};
