import { FC, useState } from 'react';
import { createCtx } from './index';
import http from '../services/http';
import { MediaDetails } from '../types/media';
import { useTranslation } from 'react-i18next';
import { PAGES } from '../data/pages';

const prefix = '/wp/v2';

type ErrorType = {
  general?: string;
};

type BackendContextType = {
  loading: boolean;
  errors?: ErrorType;
  imprintHtml: string;
  fetchImprint: () => Promise<boolean>;
  privacyPolicyHtml: string;
  fetchPrivacyPolicy: () => Promise<boolean>;
  currentMedia: MediaDetails[];
  fetchMedia: (categoryId: number) => Promise<boolean>;
};

export const [useBackend, CtxProvider] = createCtx<BackendContextType>();

export const BackendProvider: FC = (props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<ErrorType | undefined>({});
  const [imprintHtml, setImprint] = useState<string>('');
  const [privacyPolicyHtml, setPrivacyPolicy] = useState<string>('');
  const [currentMedia, setCurrentMedia] = useState<MediaDetails[]>([]);

  const fetchImprint = async (): Promise<boolean> => {
    try {
      setErrors(undefined);
      setLoading(true);

      const response = await http.get(`${prefix}/pages/${PAGES.imprint}`);
      setImprint(response.data.content.rendered);

      return true;
    } catch (err) {
      setErrors({ general: t('errors.somethingWentWrong') });
      return false;
    } finally {
      setTimeout(() => setLoading(false), 250);
    }
  };

  const fetchPrivacyPolicy = async (): Promise<boolean> => {
    try {
      setErrors(undefined);
      setLoading(true);

      const response = await http.get(`${prefix}/pages/${PAGES.privacyPolicy}`);
      setPrivacyPolicy(response.data.content.rendered);

      return true;
    } catch (err) {
      setErrors({ general: t('errors.somethingWentWrong') });
      return false;
    } finally {
      setTimeout(() => setLoading(false), 250);
    }
  };

  const fetchMedia = async (categoryId: number): Promise<boolean> => {
    try {
      setCurrentMedia([]);
      setErrors(undefined);
      setLoading(true);

      const fields = ['id', 'guid', 'description', 'caption', 'media_details'];
      const response = await http.get(
        `${prefix}/media?mlo-category=${categoryId}&_fields=${fields.join(',')}&per_page=100`
      );

      const mediaDetails: MediaDetails[] = response.data.map((x: any) => x.media_details);
      setCurrentMedia(mediaDetails);

      return true;
    } catch (err) {
      setErrors({ general: t('errors.somethingWentWrong') });
      return false;
    } finally {
      setTimeout(() => setLoading(false), 250);
    }
  };

  const contextValue: BackendContextType = {
    loading,
    errors,
    imprintHtml,
    fetchImprint,
    privacyPolicyHtml,
    fetchPrivacyPolicy,
    currentMedia,
    fetchMedia,
  };
  return <CtxProvider value={contextValue}>{props.children}</CtxProvider>;
};
