import Cookies from 'js-cookie';
import { FC } from 'react';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router';
import { GOOGLE_ANALYTICS_TRACKING_CODE } from '../config/env';

class Analytics {
  static currentUrl = '';
  static initialized = false;

  static isInitialized() {
    return Analytics.initialized;
  }

  static initialize() {
    if (!Analytics.isInitialized()) {
      if (
        Cookies.get('klaro') &&
        !(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
      ) {
        if (JSON.parse(Cookies.get('klaro')!).googleAnalytics) {
          ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_CODE);
          Analytics.initialized = true;
        }
      }
    }
  }

  static log(url: any) {
    if (!Analytics.isInitialized()) {
      Analytics.initialize();
    }

    if (
      Cookies.get('klaro') &&
      !(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    ) {
      if (JSON.parse(Cookies.get('klaro')!).googleAnalytics) {
        if (Analytics.currentUrl !== url) {
          ReactGA.pageview(url);
          Analytics.currentUrl = url;
        }
      }
    }
  }

  static event(category: any, action: any, label: any) {
    if (
      Cookies.get('klaro') &&
      !(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    ) {
      if (JSON.parse(Cookies.get('klaro')!).googleAnalytics) {
        ReactGA.event({
          category: category,
          action: action,
          label: label,
        });
      }
    }
  }
}

const AnalyticsWrapper: FC = ({ children }) => {
  const history = useHistory();

  history.listen((location) => {
    Analytics.log(location.pathname);
  });
  return <>{children}</>;
};

export { Analytics, AnalyticsWrapper };
