import { ChangeEvent, FC, HTMLProps, useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupErrorToErrorObject } from '../../../utils/validation';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';

//#region Iconss
const defaultInvalidIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 text-red-500"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);
const defaultValidIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 text-green-500"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);
//#endregion

interface Props extends HTMLProps<HTMLTextAreaElement> {
  name: string;
  icon: string;
  error?: string;
  validationSchema?: yup.AnySchema;
  validIcon?: JSX.Element;
  invalidIcon?: JSX.Element;
}

export const Textarea: FC<Props> = (props) => {
  const { type, validationSchema, icon, validIcon, invalidIcon, ...inputProps } = props;

  //#region Validation
  const [error, setError] = useState<string | undefined>(undefined);
  const [touched, setTouched] = useState(false);
  const [valid, setValid] = useState(false);

  const validate = useCallback(
    (value: string) => {
      if (!validationSchema) return;

      const schema = yup.object().shape({
        [props.name]: validationSchema,
      });

      schema
        .validate({ [props.name]: value }, { abortEarly: false })
        .then(() => {
          setError(undefined);
          setValid(true);
        })
        .catch((err: yup.ValidationError) => {
          const error = yupErrorToErrorObject(err);
          setError(error[props.name].join('; ') || '');
          setValid(false);
        });
    },
    [props.name, validationSchema]
  );

  useEffect(() => {
    if (typeof props.value === 'string' && props.value !== '') {
      setTouched(true);
      validate(props.value);
    }
  }, [props.value, validate]);
  //#endregion

  //#region Input element
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (props.onChange) {
      props.onChange(e);
    }
    if (touched) {
      validate(e.currentTarget.value);
    }
  };

  const handleFocusLeave = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setTouched(true);
    validate(e.currentTarget.value);
  };
  //#endregion

  const isError = props.error !== undefined || error !== undefined;
  const isValid = validationSchema && touched && valid && !isError;

  const inputError = isError && (
    <p className="mt-1 text-xs text-right text-error" id={`${props.name}-error`}>
      {error || props.error}
    </p>
  );

  const size = (props.value as string).length;
  const min = props.minLength;
  const max = props.maxLength;

  const inputSizeDisplay = (
    <span
      className={clsx('ml-4 text-xs text-secondary text-right', {
        'text-error': (min ? size < min : false) || (max ? size > max : false),
        'text-success': (min ? size > min : true) && (max ? size < max : true),
      })}
    >
      {size} / {max}
    </span>
  );

  return (
    <div className="w-full flex flex-col mb-8 mr-8 last:mr-0">
      <label className="mb-4 font-semibold text-sm">{inputProps.label}</label>
      <div className="relative flex flex-row items-start">
        <div className="p-5 rounded-md bg-primary absolute top-0 left-0 z-50">
          <div className="tablericon">
            <ReactSVG src={icon} className="w-5 h-5 text-white" />
          </div>
        </div>

        <div className="my-2 pl-8 w-full">
          <textarea
            className={clsx(
              'w-full h-48 block p-2 pl-9 rounded-md bg-input',
              'text-sm font-semibold ',
              'outline-none border-none resize-none',
              'focus:outline-none focus:ring-0',
              'disabled:opacity-50'
            )}
            {...inputProps}
            id={props.name}
            aria-invalid={isError ? 'true' : 'false'}
            aria-describedby={`${props.name}-error`}
            onChange={handleChange}
            onBlur={handleFocusLeave}
          />

          <div
            className={clsx(
              'absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none',
              {
                'text-error': isError,
                'text-success': isValid,
              }
            )}
          >
            {isError && (invalidIcon || defaultInvalidIcon)}
            {isValid && (validIcon || defaultValidIcon)}
          </div>
        </div>
      </div>
      {touched && inputSizeDisplay}
      {inputError}
    </div>
  );
};
