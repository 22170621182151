import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '../../components/common/skeleton/Skeleton';
import { Page } from '../../components/layout/page/page';
import { useBackend } from '../../contexts/backend';

const SkeletonTemlate: FC = () => {
  return (
    <div className="legalpage">
      <div className="px-8">
        <div className="content">
          <Skeleton.H1 />
          <Skeleton.Spacer height={10} />

          <Skeleton.H2 />
          <Skeleton.Line />
          <Skeleton.Line />
          <Skeleton.Line />
          <Skeleton.Line />
          <Skeleton.Spacer height={10} />

          <Skeleton.H2 />
          <Skeleton.Line />
          <Skeleton.Line />
          <Skeleton.Line />
          <Skeleton.Line />
          <Skeleton.Spacer height={10} />

          <Skeleton.H2 />
          <Skeleton.Line />
          <Skeleton.Line />
          <Skeleton.Line />
          <Skeleton.Line />
          <Skeleton.Spacer height={10} />

          <Skeleton.H2 />
          <Skeleton.Line />
          <Skeleton.Line />
          <Skeleton.Line />
          <Skeleton.Line />
          <Skeleton.Spacer height={10} />

          <Skeleton.H2 />
          <Skeleton.Line />
          <Skeleton.Line />
          <Skeleton.Line />
          <Skeleton.Line />
        </div>
      </div>
    </div>
  );
};

export const PrivacyPolicy: FC = () => {
  const { t } = useTranslation();
  const { loading, privacyPolicyHtml, fetchPrivacyPolicy } = useBackend();

  useEffect(() => {
    fetchPrivacyPolicy();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || privacyPolicyHtml === '') {
    return <SkeletonTemlate />;
  }

  return (
    <Page.Legal
      title={t('seo:pages.privacypolicy.title')}
      description={t('seo:pages.privacypolicy.description')}
      className="privacypolicy"
      breadcrumbTitle={t('pages.privacypolicy.title')}
      breadcrumbTitleUrl="/datenschutz"
      innerHtml={privacyPolicyHtml}
    />
  );
};
