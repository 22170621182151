import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import Button from '../../../../components/common/button/Button';
import Paragraph from '../../../../components/common/paragraph/Paragraph';
import './categorycard.scss';

interface Props {
  icon: any;
  title: string;
  description?: string;
  to: string;
}

export const CategoryCard: FC<Props> = (props) => {
  const { icon, title, description, to } = props;
  const { t } = useTranslation();

  return (
    <div className="service-card flex flex-grow">
      <div className="pt-8">
        <div className="content">
          <div className=" -mt-8 px-4 flex flex-col  box">
            <div className="icon">
              <span className="icon-wrapper">
                <ReactSVG src={icon} className="icon-svg" />
              </span>
            </div>
            <div className="text">
              <h3>{title}</h3>
              <Paragraph.Small customClassName="mt-3 flex-1" centered leadingLoose html>
                {description}
              </Paragraph.Small>
              <div className="link">
                <Link to={to}>
                  <Button>{t('pages.home.services.card.more')}</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
