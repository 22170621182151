import JsCookie from 'js-cookie';

interface Cookies {
  mapbox: boolean;
  googleRecaptcha: boolean;
  googleAnalytics: boolean;
}

/**
 * Get Klaro cookies
 * @returns {Cookies} Klaro cookies
 */
export function getCookies(): Cookies {
  const klaroCookie = JsCookie.get('klaro');
  if (klaroCookie !== undefined) {
    return JSON.parse(klaroCookie);
  }

  return {
    mapbox: false,
    googleRecaptcha: false,
    googleAnalytics: false,
  };
}

/**
 * Update cookies
 * @param {Partial<Cookies>} data Cookies to update
 */
export function setCookies(data: Partial<Cookies>) {
  const cookies = getCookies();

  cookies.mapbox = data.mapbox || cookies.mapbox;
  cookies.googleRecaptcha = data.googleRecaptcha || cookies.googleRecaptcha;
  cookies.googleAnalytics = data.googleRecaptcha || cookies.googleAnalytics;

  JsCookie.set('klaro', JSON.stringify(cookies));
}
