import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Obfuscate from 'react-obfuscate';
// assets
import atIcon from '../../../../assets/icons/interface/at.svg';
import mapPinIcon from '../../../../assets/icons/interface/map-pin.svg';
import phoneIcon from '../../../../assets/icons/interface/phone.svg';
import Paragraph from '../../../../components/common/paragraph/Paragraph';
import { Section } from '../../section/Section';
import { ContactDetail } from './ContactDetail';

export const ContactDetails: FC = () => {
  const { t } = useTranslation();
  return (
    <div className=" bg-primary text-white px-8" id="kontakt">
      <div className="container mx-auto">
        <Section
          title={t('pages.home.contactdetails.title')}
          description={t('pages.home.contactdetails.description')}
          className=""
        >
          <div className="contact-details grid grid-cols-1 md:grid-cols-3 gap-12 items-start flex-wrap justify-center">
            <ContactDetail icon={phoneIcon} title="Telefonnummer">
              <Obfuscate tel="+49 6841 170881" />
            </ContactDetail>

            <ContactDetail icon={mapPinIcon} title="Adresse">
              <div className="text-center">
                <Paragraph selectable>
                  <Obfuscate element="span">SooN-Werbung</Obfuscate>
                  <br />
                  <Obfuscate element="span">Beerenweg 4</Obfuscate>
                  <br />
                  <Obfuscate element="span">66424 Homburg</Obfuscate>
                </Paragraph>
              </div>
            </ContactDetail>

            <ContactDetail icon={atIcon} title="Email">
              <Obfuscate email="info@soon-werbung.de" />
            </ContactDetail>
          </div>
        </Section>
      </div>
    </div>
  );
};
