import clsx from 'clsx';
import { FC, useEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
// assets
import arrowUp from '../../../assets/icons/interface/arrow-up-1.svg';
import { useLocomotive } from '../../../contexts/locomotive';

const BUTTON_VISIBLE_AT = window.innerHeight * 0.7;

export const ScrollToTopButton: FC = () => {
  const locomotive = useLocomotive();
  const ref = useRef<HTMLDivElement>(null);

  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (locomotive.y > BUTTON_VISIBLE_AT) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [locomotive.y, setVisible]);

  const scrollToTop = () => {
    locomotive.scroll.scrollTo('top');
  };

  return (
    <div
      ref={ref}
      className={clsx(
        'cursor-pointer fixed right-8  bottom-8 md:right-12 md:bottom-12 p-4 rounded-md z-scrolltotop',
        'bg-primary hover:bg-btn-hover',
        'transition-all duration-300 ease-linear',
        {
          'opacity-100': visible,
          'opacity-0': !visible,
        },
        {
          'pointer-events-auto': visible,
          'pointer-events-none': !visible,
        },
        'shadow-md'
      )}
      onClick={scrollToTop}
    >
      <ReactSVG src={arrowUp} className="text-white w-2.5 h-2.5" />
    </div>
  );
};
