import { ResizeObserver } from '@juggle/resize-observer';
import LocomotiveScroll from 'locomotive-scroll';
import { FC, useEffect, useState } from 'react';
import { createCtx } from './index';

type LocomotiveContextType = {
  scroll: any;
  y: number;
};

export const [useLocomotive, CtxProvider] = createCtx<LocomotiveContextType>();

export const ScrollableContent: FC = (props) => {
  return (
    <div className="scrollable-content-wrapper flex flex-col" data-scroll-container>
      {props.children}
    </div>
  );
};

export const LocomotiveProvider: FC = (props) => {
  const [scroll, setScroll] = useState<any>();
  const [y, setY] = useState<number>(0);

  useEffect(() => {
    // get scroll container
    const scrollContainer = document.querySelector('.scrollable-content-wrapper');
    if (scrollContainer) {
      const locomotiveScroll = new LocomotiveScroll({
        el: scrollContainer,
        smooth: true,
        smartphone: { smooth: false },
        tablet: { smooth: false },
      });
      setScroll(locomotiveScroll);

      const resizeObserver = new ResizeObserver((entries: any) => {
        locomotiveScroll?.scroll.update();
      });

      resizeObserver.observe(document.body);

      // get scroll y position
      locomotiveScroll.on('scroll', () => {
        setY(locomotiveScroll.scroll.instance.scroll.y);
      });
      // update scroll
    } else {
      // scroll container not found
    }

    // clean up
    return () => {
      scroll?.destroy();
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <CtxProvider value={{ scroll, y }}>{props.children}</CtxProvider>;
};
