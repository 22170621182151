import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CATEGORIES } from '../../../../data/categories';
import { Section } from '../../section/Section';
import { CategoryCard } from './CategoryCard';
import './services.scss';

export const Services: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="services-container bg-black text-white px-8 ">
      <div className="container mx-auto">
        <Section
          id="leistungen"
          title={t('pages.home.services.title')}
          description={t('pages.home.services.description')}
          className=""
        >
          <div className="services">
            {CATEGORIES.map((category, i) => {
              return (
                <CategoryCard
                  key={i}
                  icon={category.icon}
                  title={category.title}
                  description={category.shortDescription}
                  to={category.path}
                />
              );
            })}
            <div className="filler hidden xl:block 2xl:hidden"></div>{' '}
          </div>
        </Section>
      </div>
    </div>
  );
};
