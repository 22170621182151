// import Klaro with CSS
// import only the consent manager (no UI components)
import 'klaro/dist/cm';
import * as Klaro from 'klaro/dist/klaro-no-css';

declare global {
  interface Window {
    klaro: any;
    klaroConfig: any;
  }
}

const klaroConfig = {
  translations: {
    de: {
      consentNotice: {
        description:
          'Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern.',
        learnMore: 'Mehr erfahren',
      },
      consentModal: {
        description:
          'Hier können Sie einsehen und anpassen, welche Information wir über Sie sammeln.',
        title: 'Datenschutz-Einstellungen',
      },
      privacyPolicy: {
        text: 'Weitere Details finden Sie in unserer {privacyPolicy}.',
      },
      ok: 'Alle akzeptieren',
      decline: 'Ablehnen',
      purposes: {
        essential: {
          title: 'Essentiell',
          description:
            'Diese Cookies werden für die einwandfreie Funktion und das Design der Seite benötigt. Diese beinhalten keine persönlichen Daten.',
        },
        functional: {
          title: 'Funktional',
          description:
            'Diese Cookies werden für die einwandfreie Funktion und das Design der Seite benötigt.',
        },
        marketing: {
          title: 'Marketing',
          description:
            'Diese Skripts und Cookies werden verwendet, um mehr über die Besucher unserer Webseite zu erfahren. Unter anderem werden Herkunft, Bildschirmauflösung und Gerätetyp verarbeitet.',
        },
      },
    },
  },
  services: [
    {
      name: 'klaro',
      title: 'klaro: Consent Management',
      purposes: ['essential'],
      required: true,
    },
    {
      name: 'googleRecaptcha',
      title: 'Google Recaptcha',
      purposes: ['functional'],
    },
    {
      name: 'mapbox',
      title: 'Mapbox',
      purposes: ['functional'],
    },
    {
      name: 'googleAnalytics',
      title: 'Google Analytics',
      purposes: ['marketing'],
    },
  ],
  privacyPolicy: '/datenschutz',
  acceptAll: true,
  lang: 'de',
};

/**
 * Initialize and display Klaro cookie consent
 */
export function initKlaro() {
  // we assign the Klaro module to the window, so that we can access it in JS
  window.klaro = Klaro;
  window.klaroConfig = klaroConfig;

  // we set up Klaro with the config
  Klaro.setup(klaroConfig);
}
