import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import http from '../services/http';
import { createCtx } from './index';
import { CONTACT_FORMS } from '../data/contact-forms';

const prefix = '/contact-form-7/v1';

type ContactFormData = {
  name: string;
  phone: string;
  email: string;
  message: string;
};

type ErrorType = {
  general?: string;
};

type ContactContextType = {
  loading: boolean;
  errors?: ErrorType;
  submit: (data: ContactFormData, captchaToken: string) => Promise<boolean>;
};

export const [useContact, CtxProvider] = createCtx<ContactContextType>();

export const ContactProvider: FC = (props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<ErrorType | undefined>({});

  const submit = async (data: ContactFormData, captchaToken: string): Promise<boolean> => {
    try {
      setErrors(undefined);
      setLoading(true);

      const formData = new FormData();
      formData.append('_wpcf7_recaptcha_response', captchaToken);
      formData.append('your-name', data.name);
      formData.append('your-phone', data.phone);
      formData.append('your-email', data.email);
      formData.append('your-message', data.message);

      const response = await http.post(
        `${prefix}/contact-forms/${CONTACT_FORMS.contactForm}/feedback`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: '*/*',
          },
        }
      );

      console.log(response.data);
      switch (response.data.status as string) {
        case 'mail_sent':
          return true;
        case 'validation_failed':
          setErrors({ general: 'Validation error.' });
          return false;
        case 'spam':
          setErrors({ general: t('errors.spam') });
          return false;
        default:
          throw new Error();
      }
    } catch (err) {
      console.error(err);
      setErrors({ general: t('errors.somethingWentWrong') });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const contextValue = { loading, errors, submit };
  return <CtxProvider value={contextValue}>{props.children}</CtxProvider>;
};

//#region HOC
/**
 * HOC providong the contact context to its child component
 * @param Component The component to provide the context to
 */
export const withContactContext = <P extends Object>(Component: React.ComponentType<P>) => {
  const WithContactContext: FC<P> = (props) => {
    return (
      <ContactProvider>
        <Component {...props} />
      </ContactProvider>
    );
  };
  return WithContactContext;
};
//#endregion
