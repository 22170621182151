import { ResizeObserver } from '@juggle/resize-observer';
import clsx from 'clsx';
import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
// assets
import menuIcon from '../../../assets/icons/interface/menu.svg';
import closeIcon from '../../../assets/icons/interface/x.svg';
import logo from '../../../assets/images/logo.png';
import { useLocomotive } from '../../../contexts/locomotive';
import { CATEGORIES } from '../../../data/categories';
import './index.scss';
import { NavbarItem } from './NavbarItem';
import { NavbarSubItem } from './NavbarSubitem';

export const Navbar: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const locomotive = useLocomotive();
  const { scroll } = locomotive;

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  useLayoutEffect(() => {
    const header = document.getElementById('navbar');
    const mainContent = document.getElementById('main-content');

    if (header && mainContent) {
      mainContent.style.paddingTop = header.clientHeight + 'px';

      const resizeObserver = new ResizeObserver((entries: any) => {
        const header = document.getElementById('navbar');
        const mainContent = document.getElementById('main-content');
        if (header && mainContent) {
          mainContent.style.paddingTop = header.clientHeight + 'px';
        }
      });

      resizeObserver.observe(document.getElementById('navbar')!);
    }
  }, []);

  //#region update navbar height on resize
  useEffect(() => {
    const header = document.getElementById('navbar');
    const menuWrapper = document.getElementById('menu-wrapper');
    const menu = document.getElementById('menu');

    if (menu && menuWrapper && header && window.innerWidth < 1280) {
      menuWrapper.style.top = header.clientHeight + 'px';
      menu.style.height = window.innerHeight - header.clientHeight + 'px';
    }

    const listener = (event: Event) => {
      if (menuWrapper && menu && header && window.innerWidth < 1280) {
        menuWrapper.style.top = header.clientHeight + 'px';
        menu.style.height = window.innerHeight - header.clientHeight + 'px';
        if (menuOpen) {
          setMenuOpen(false);
        }
      }

      if (menuWrapper && menu && header && window.innerWidth > 1279) {
        menuWrapper.style.top = '0px';
        menu.style.height = `auto`;
        setMenuOpen(false);
      }
    };
    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  });
  //#endregion

  //#region Scroll to contact
  const scrollTo = (id: string) => {
    setTimeout(() => {
      locomotive?.scroll?.scrollTo(document.getElementById(id)?.offsetTop);
    }, 250);
  };
  //#endregion

  const branding = (
    <Link
      to="/"
      onClick={() => {
        scroll?.scrollTo('top');
        setMenuOpen(false);
      }}
    >
      <div className="branding">
        <img src={logo} alt="company logo" className="auto h-6 sm:h-8 xl:h-14" />
      </div>
    </Link>
  );

  const menuButton = (
    <div
      className="mobile-menu-icon flex xl:hidden cursor-pointer items-center"
      onClick={() => setMenuOpen(!menuOpen)}
    >
      <ReactSVG src={menuOpen ? closeIcon : menuIcon} className="h-6 w-6" />
    </div>
  );

  const contactButton = (
    <NavbarItem
      title={t('common.nav.items.contact')}
      to="/#kontakt"
      onClick={() => {
        setMenuOpen(false);
        if (location.hash === '#kontakt') {
          scrollTo('kontakt');
        }
      }}
    />
  );

  return (
    <nav className="navbar" id="navbar" data-scroll data-scroll-sticky data-scroll-target="#app">
      <div className="container mx-auto flex flex-row justify-between">
        {branding}
        {menuButton}

        <div
          className={clsx('menu-wrapper', {
            'menu-open': menuOpen,
          })}
          id="menu-wrapper"
        >
          <ul className="menu" id="menu">
            <NavbarItem
              title={t('common.nav.items.home')}
              to="/"
              onClick={() => {
                setMenuOpen(!menuOpen);
                scroll?.scrollTo('top');
              }}
            />
            {CATEGORIES.map((category, i) => {
              return (
                <NavbarItem
                  key={i}
                  title={category.title}
                  to={category.path}
                  onClick={() => setMenuOpen(false)}
                >
                  {category.services.map((service, j) => {
                    return (
                      <NavbarSubItem
                        key={j}
                        title={service.title}
                        to={`${category.path}${service.path}`}
                        icon={service.icon}
                        onClick={() => setMenuOpen(false)}
                      />
                    );
                  })}
                </NavbarItem>
              );
            })}
            {contactButton}
          </ul>
        </div>
      </div>
    </nav>
  );
};
