import { FC } from 'react';
import { Breadcrumb } from '../../common/breadcrumb/Breadcrumb';
import { SEO, SEOProps } from '../../../utils/SEO';

import './page.scss';

type Props = {
  className?: string;
} & SEOProps;

type LegalPageProps = {
  /** HTML to display on the page */
  innerHtml: string;

  /** Page title for breadcrumb */
  breadcrumbTitle: string;

  /** Page URL for breadcrumb */
  breadcrumbTitleUrl: string;
} & Props;

const Legal: FC<LegalPageProps> = (props) => {
  const { title, breadcrumbTitle, description, className, breadcrumbTitleUrl, innerHtml } = props;

  return (
    <div className={className + ' legalpage'}>
      <SEO title={title} description={description} />

      <Breadcrumb>
        <Breadcrumb.Home />
        <Breadcrumb.Item title={breadcrumbTitle} to={breadcrumbTitleUrl} active />
      </Breadcrumb>

      <div className="px-8">
        <div className="content" dangerouslySetInnerHTML={{ __html: innerHtml }}></div>
      </div>
    </div>
  );
};
interface Type {
  Legal: typeof Legal;
}
export const Page: FC<Props> & Type = (props) => {
  const { className, ...seoProps } = props;

  return (
    <div className={className}>
      <SEO
        {...seoProps}
        ogTitle={props.ogTitle || props.title}
        ogDescription={props.ogDescription || props.description}
        noIndex={props.noIndex}
      />

      {props.children}
    </div>
  );
};

Page.Legal = Legal;
