import clsx from 'clsx';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import homeIcon from '../../../assets/icons/interface/house-2.svg';
// assets
import chevronRight from '../../../assets/icons/interface/interface-arrows-button-right.svg';
import './breadcrumb.scss';

const Home: FC = () => {
  return (
    <li className="breadcrumb-item-home">
      <Link to="/">
        <ReactSVG src={homeIcon} className="tablericon" />
      </Link>
    </li>
  );
};

interface Props {
  active?: boolean;
  title: string;
  to: string;
}
const Item: FC<Props> = (props) => {
  const { title, to, active } = props;
  return (
    <li
      className={clsx('breadcrumb-item', {
        active: active,
      })}
    >
      <ReactSVG src={chevronRight} className="tablericon" />
      <Link to={to}>
        <div className="text">{title}</div>
      </Link>
    </li>
  );
};

interface Type {
  Home: typeof Home;
  Item: typeof Item;
}
export const Breadcrumb: FC<{}> & Type = (props) => {
  return (
    <div className="breadcrumb px-8 text-white">
      <div className="container mx-auto flex flex-row">
        <ol className="flex items-center space-x-4">{props.children}</ol>
      </div>
    </div>
  );
};

Breadcrumb.Home = Home;
Breadcrumb.Item = Item;

export default Breadcrumb;
