import { FC, HTMLProps } from 'react';

interface Props extends HTMLProps<HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset';
}

const Button: FC<Props> = (props) => {
  const { type, children, ...buttonProps } = props;

  return (
    <button
      {...buttonProps}
      type={type}
      className="btn inline-flex flex-row px-6 py-3 items-center font-semibold rounded-sm text-white cursor-pointer justify-center bg-primary transition-all focus:outline-none hover:bg-btn-hover"
    >
      <div className="text-sm">{children}</div>
    </button>
  );
};

export default Button;
