import React, { FC, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { IconButton } from '../../../../components/common/iconbutton/IconButton';
import { Input } from '../../../../components/common/inputs/Input';
import { Textarea } from '../../../../components/common/inputs/Textarea';
import Paragraph from '../../../../components/common/paragraph/Paragraph';
import { useContact, withContactContext } from '../../../../contexts/contact';
import { useCookies } from '../../../../contexts/cookies';
import Loader from 'react-loader-spinner';
// assets
import atIcon from '../../../../assets/icons/interface/at.svg';
import messageIcon from '../../../../assets/icons/interface/message.svg';
import phoneIcon from '../../../../assets/icons/interface/phone.svg';
import sendIcon from '../../../../assets/icons/interface/send.svg';
import userIcon from '../../../../assets/icons/interface/user.svg';

export const Form: FC = withContactContext(() => {
  const { t } = useTranslation();
  const cookies = useCookies();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const { submit, errors, loading } = useContact();
  const [success, setSuccess] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // check if form has already been successfully submitted
    if (success) {
      resetForm();
      return;
    }

    // reCaptcha
    if (!cookies.enableRecaptcha) return;
    if (!executeRecaptcha) return;
    const captchaToken = await executeRecaptcha('contact_form');

    // submit data
    const result = await submit({ name, phone, email, message }, captchaToken);
    if (result) setSuccess(true);
  };

  const resetForm = () => {
    setSuccess(false);
    setMessage('');
  };

  const consent = (
    <div className="row mb-9 flex items-center justify-end">
      <Paragraph.ExtraSmall secondary right leadingLoose customClassName="md:w-6/12">
        Um das Kontaktformular verwenden zu können, müssen Sie das{' '}
        <strong onClick={() => cookies.enableRecaptcha()}>Google-Recaptcha Cookie </strong>{' '}
        akzeptieren.
      </Paragraph.ExtraSmall>
    </div>
  );

  return (
    <form onSubmit={handleSubmit}>
      {errors?.general && (
        <div className="row flex flex-col">
          <p className="text-error">{errors.general}</p>
        </div>
      )}
      <div className="row flex flex-col lg:flex-row">
        <Input
          type="text"
          name="name"
          icon={userIcon}
          label={t('pages.home.contactform.fields.name.label')}
          placeholder={t('pages.home.contactform.fields.name.placeholder')}
          autoComplete="name"
          max={255}
          required
          disabled={!cookies.recaptchaEnabled || loading || success}
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          validationSchema={yup
            .string()
            .required(t('pages.home.contactform.fields.name.errors.required'))
            .max(255, t('pages.home.contactform.fields.name.errors.max'))}
        />
        <Input
          type="tel"
          name="phone"
          icon={phoneIcon}
          label={t('pages.home.contactform.fields.phone.label')}
          placeholder={t('pages.home.contactform.fields.phone.placeholder')}
          autoComplete="tel"
          required
          disabled={!cookies.recaptchaEnabled || loading || success}
          value={phone}
          onChange={(e) => setPhone(e.currentTarget.value)}
          validationSchema={yup
            .string()
            .required(t('pages.home.contactform.fields.phone.errors.required'))
            .matches(/^\+?\d+$/, t('pages.home.contactform.fields.phone.errors.phone'))
            .max(20, t('pages.home.contactform.fields.phone.errors.max'))}
        />
      </div>
      <div className="row">
        <Input
          type="email"
          name="email"
          icon={atIcon}
          label={t('pages.home.contactform.fields.email.label')}
          placeholder={t('pages.home.contactform.fields.email.placeholder')}
          autoComplete="email"
          required
          disabled={!cookies.recaptchaEnabled || loading || success}
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          validationSchema={yup
            .string()
            .required(t('pages.home.contactform.fields.email.errors.required'))
            .email(t('pages.home.contactform.fields.email.errors.email'))
            .max(255, t('pages.home.contactform.fields.email.errors.max'))}
        />
      </div>
      <div className="row mb-6 ">
        <Textarea
          name="message"
          icon={messageIcon}
          label={t('pages.home.contactform.fields.message.label')}
          placeholder={t('pages.home.contactform.fields.message.placeholder')}
          minLength={20}
          maxLength={5000}
          disabled={!cookies.recaptchaEnabled || loading || success}
          value={message}
          onChange={(e) => setMessage(e.currentTarget.value)}
          validationSchema={yup
            .string()
            .min(20, t('pages.home.contactform.fields.message.errors.required'))
            .max(5000, t('pages.home.contactform.fields.message.errors.required'))}
        />
      </div>

      {!cookies.recaptchaEnabled && consent}
      {cookies.recaptchaEnabled && (
        <div className="row mb-9 flex items-center justify-end">
          <Paragraph.ExtraSmall secondary right leadingLoose customClassName="md:w-9/12">
            Diese Website ist durch reCAPTCHA geschützt und es gelten die{' '}
            <a className="font-semibold underline" href="https://policies.google.com/privacy">
              Datenschutzbestimmungen
            </a>{' '}
            und{' '}
            <a className="font-semibold underline" href="https://policies.google.com/terms">
              Nutzungsbedingungen
            </a>{' '}
            von Google.
          </Paragraph.ExtraSmall>
        </div>
      )}

      <div className="row flex justify-end">
        <IconButton
          type="submit"
          disabled={!cookies.recaptchaEnabled || loading}
          variant={success ? 'success' : 'default'}
          icon={
            loading ? (
              <Loader width={20} height={20} type="TailSpin" color="#fff" secondaryColor="#fff" />
            ) : (
              sendIcon
            )
          }
        >
          {loading
            ? t('pages.home.contactform.loading')
            : success
            ? t('pages.home.contactform.success')
            : t('pages.home.contactform.submit')}
        </IconButton>
      </div>
    </form>
  );
});
