import { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  to: string;
}
export const WidgetItem: FC<Props> = (props) => {
  const { title, to } = props;

  return (
    <li className="mb-3 uppercase text-xs transition-colors duration-200 hover:text-primary">
      <Link to={to}>{title}</Link>
    </li>
  );
};
