import L, { Map as LeafletMap } from 'leaflet';
import { FC, useRef } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { MAPBOX_ACCESS_TOKEN } from '../../../../config/env';

const position: L.LatLngExpression = [49.34766, 7.36665];

const MapImpl: FC = () => {
  const mapRef = useRef<LeafletMap>();

  //#region handle page resize
  const listener = (ev: Event) => {
    mapRef.current?.invalidateSize();
  };
  window.addEventListener('resize', listener);

  //#endregion

  return (
    <MapContainer
      center={position}
      zoom={17}
      scrollWheelZoom={false}
      zoomControl={false}
      dragging={false}
      className=" h-full w-full"
      doubleClickZoom={false}
      trackResize={false}
      touchZoom={false}
      whenCreated={(mapInstance) => {
        mapRef.current = mapInstance;
      }}
    >
      <div className="mapbox-wordmark"></div>{' '}
      <TileLayer
        attribution='&copy; <a href="https://www.mapbox.com/about/maps/" rel="noreferrer">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright" rel="noreferrer">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank" rel="noreferrer">Improve this map</a></strong>'
        url={`https://api.mapbox.com/styles/v1/soon-werbung/ckolkvc121rmm17l32ln8oekh/tiles/256/{z}/{x}/{y}@2x?access_token=${MAPBOX_ACCESS_TOKEN}`}
      />
      <Marker
        position={position}
        icon={L.divIcon({
          className: 'custom icon',
          html: '<div class="pulsating-marker"><div class="outer"></div></div>',
        })}
      />
    </MapContainer>
  );
};

export default MapImpl;
