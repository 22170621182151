import { FC, useEffect } from 'react';
import { initKlaro } from './config/klaro';
import { BackendProvider } from './contexts/backend';
import { CookiesProvider } from './contexts/cookies';
import { AppRouter } from './router';
import { withSplashscreen } from './components/util/splashscreen/withSplashscreen';

import './index.scss';

const App: FC = withSplashscreen(() => {
  useEffect(() => {
    initKlaro();
  }, []);

  return (
    <div id="app" className="app flex flex-col h-full w-full min-h-screen relative">
      <div className="bg bg-black fixed h-full w-full -z-5 left-0 top-0"></div>
      <AppRouter />
    </div>
  );
});

/**
 * Wraps the App inside the global providers
 */
export const AppWrapper: FC = () => {
  return (
    <CookiesProvider>
      <BackendProvider>
        <App />
      </BackendProvider>
    </CookiesProvider>
  );
};
