import { FC } from 'react';
import { Helmet } from 'react-helmet';

export type SEOProps = {
  title: string;
  description?: string;
  ogUrl?: string;
  ogType?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  noIndex?: boolean;
};
export const SEO: FC<SEOProps> = (props) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{props.title}</title>
      <meta name="description" content={props.description || ''} />

      {props.ogUrl && <meta name="og:url" content={props.ogUrl} />}
      {props.ogType && <meta name="og:type" content={props.ogType} />}
      {props.ogTitle && <meta name="og:title" content={props.ogTitle} />}
      {props.ogDescription && <meta name="og:description" content={props.ogDescription} />}
      {props.ogImage && <meta name="og:image" content={props.ogImage} />}
      {props.noIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};
