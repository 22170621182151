import { FC } from 'react';
import { Link } from 'react-router-dom';
import cookieIcon from '../../../../assets/icons/interface/cookies.svg';
import { IconButton } from '../../../../components/common/iconbutton/IconButton';
import Paragraph from '../../../../components/common/paragraph/Paragraph';
import { useCookies } from '../../../../contexts/cookies';
import Map from './Map';

export const MapContainer: FC = () => {
  const cookies = useCookies();

  const consent = (
    <div className="modal-container flex flex-col justify-center relative items-center">
      <div className="modal inline-flex flex-col justify-center  items-start">
        <h4>Externer Inhalt - Mapbox</h4>
        <Paragraph.Small>
          Hier finden Sie Inhalte eines Drittanbieters, Mapbox. Um diese nutzen zu können, müssen
          Sie das Mapbox-Cookie akzeptieren.
        </Paragraph.Small>
        <Paragraph.ExtraSmall customClassName="mb-4">
          Mehr Informationen finden Sie in unserer{' '}
          <Link to="/datenschutz" className="font-semibold underline">
            Datenschutzerklärung
          </Link>
        </Paragraph.ExtraSmall>
        <IconButton variant="secondary" onClick={() => cookies.enableMapbox()} icon={cookieIcon}>
          Mapbox-Cookie akzeptieren
        </IconButton>
      </div>
    </div>
  );

  if (!cookies.mapboxEnabled) return consent;

  return (
    <div className="map-container">
      <Map />
      <div className="overlay"></div>
    </div>
  );
};
