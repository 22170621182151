import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import homeIcon from '../../assets/icons/interface/house-2.svg';
import { IconButton } from '../../components/common/iconbutton/IconButton';
import Paragraph from '../../components/common/paragraph/Paragraph';
import { Page } from '../../components/layout/page/page';

export const NotFound: FC = () => {
  const { t } = useTranslation(['translation', 'seo']);
  return (
    <Page
      title={t('seo:pages.error.title')}
      description={t('seo:pages.error.description')}
      className="flex-1"
      noIndex
    >
      <div className="px-8 py-16">
        <div className="container mx-auto">
          <h1 className="text-primary font-semibold text-2xl mb-4 leading-loose">
            {t('pages.error.header')}
          </h1>
          <Paragraph.Small customClassName="mb-6">{t('pages.error.description')}</Paragraph.Small>
          <Link to="/">
            <IconButton icon={homeIcon} className="text-white">
              {t('pages.error.action')}
            </IconButton>
          </Link>
        </div>
      </div>
    </Page>
  );
};
