import { FC, ReactNode } from 'react';
import clsx from 'clsx';

interface Props {
  children: ReactNode;
  centered?: boolean;
  secondary?: boolean;
  customClassName?: string;
  leadingLoose?: boolean;
  left?: boolean;
  right?: boolean;
  html?: boolean;
  selectable?: boolean;
}

type Sizes = 'extrasmall' | 'small' | 'normal' | 'large';
const makeParagraph = (props: Props, size?: Sizes) => {
  return (
    // eslint-disable-next-line react/no-danger-with-children
    <p
      className={clsx(
        {
          'text-xs leading-loose': size === 'extrasmall',
          'text-sm leading-loose': size === 'small',
          'text-base': size === 'normal',
          'text-xl': size === 'large',
        },
        {
          'text-center': props.centered,
          'text-left': props.left,
          'text-right': props.right,
          'leading-loose': props.leadingLoose,
          'text-p-secondary': props.secondary,
          'text-p-white': !props.secondary,
          'select-text': props.selectable,
        },
        props.customClassName
      )}
      dangerouslySetInnerHTML={props.html ? { __html: props.children as string } : undefined}
      children={props.html ? undefined : props.children}
    ></p>
  );
};

const ExtraSmall: FC<Props> = (props) => {
  return makeParagraph(props, 'extrasmall');
};

const Small: FC<Props> = (props) => {
  return makeParagraph(props, 'small');
};

const Normal: FC<Props> = (props) => {
  return makeParagraph(props, 'normal');
};

const Large: FC<Props> = (props) => {
  return makeParagraph(props, 'large');
};

interface Type {
  Small: typeof Small;
  Normal: typeof Normal;
  Large: typeof Large;
  ExtraSmall: typeof ExtraSmall;
}
export const Paragraph: FC<Props> & Type = (props) => {
  return makeParagraph(props);
};

Paragraph.ExtraSmall = ExtraSmall;
Paragraph.Small = Small;
Paragraph.Normal = Normal;
Paragraph.Large = Large;

export default Paragraph;
