import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Page } from '../../components/layout/page/page';
import { useLocomotive } from '../../contexts/locomotive';
import { ContactDetails } from './sections/contactdetails/ContactDetails';
import { ContactForm } from './sections/contactform/ContactForm';
// sections
import { Landing } from './sections/landing/Landing';
import { OurWork } from './sections/ourwork/OurWork';
import { Services } from './sections/services/Services';

export const Home: FC = () => {
  const location = useLocation();
  const locomotive = useLocomotive();
  const { t } = useTranslation();

  //#region check scroll to contact
  useEffect(() => {
    const scrollTo = (id: string) => {
      setTimeout(() => {
        locomotive?.scroll?.scrollTo(document.getElementById(id)?.offsetTop);
      }, 250);
    };

    switch (location.hash) {
      case '#leistungen':
        scrollTo('leistungen');
        break;
      case '#referenzen':
        scrollTo('referenzen');
        break;
      case '#kontakt':
        scrollTo('kontakt');
        break;

      default:
        break;
    }
  }, [location.hash, locomotive.scroll]);
  //#endregion

  return (
    <Page
      title={t('seo:pages.home.title')}
      description={t('seo:pages.home.description')}
      className="home overflow-x-hidden relative"
    >
      <Landing />
      <Services />
      <OurWork />
      <ContactDetails />
      <ContactForm />
    </Page>
  );
};
