import { FC } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import Paragraph from '../../../../components/common/paragraph/Paragraph';
import { GOOGLE_RECAPTCHAV3_PUBLIC_KEY } from '../../../../config/env';
import { useCookies } from '../../../../contexts/cookies';
import './contactform.scss';
import { Form } from './Form';
import { MapContainer } from './MapContainer';

export const ContactForm: FC = () => {
  const { t } = useTranslation();
  const cookies = useCookies();

  return (
    <div className="contact-area overflow-auto">
      <MapContainer />
      <div className="px-8 w-full">
        <div className="contact-form-container ">
          <div className="contact-form ">
            <h3 className="text-primary font-semibold text-2xl mb-4">
              {t('pages.home.contactform.title')}
            </h3>

            <Paragraph.Small customClassName="description" secondary leadingLoose>
              {t('pages.home.contactform.description')}
            </Paragraph.Small>

            {!cookies.recaptchaEnabled && <Form />}
            {cookies.recaptchaEnabled && (
              <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHAV3_PUBLIC_KEY}>
                <Form />
              </GoogleReCaptchaProvider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
