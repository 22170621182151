import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// assets
import msgIcon from '../../../assets/icons/interface/messages.svg';
import { IconButton } from '../iconbutton/IconButton';
import Paragraph from '../paragraph/Paragraph';
import './calltoaction.scss';

export const CallToAction: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="cta relative">
      <div className="container mx-auto flex flex-row z-50">
        <div className="info lg:flex-1 text-white py-16">
          <h3 className="text-2xl mb-4 font-bold">{t('common.cta.title')}</h3>
          <Paragraph.Small customClassName="description" leadingLoose>
            {t('common.cta.description')}
          </Paragraph.Small>
          <Link to="/#kontakt">
            <IconButton icon={msgIcon}>{t('common.contact')}</IconButton>
          </Link>
        </div>
        <div className="flex-1"></div>
      </div>
      <div className="bg "></div>
      <div className="image bg-cta">
        <div className="overlay  w-full h-full absolute left-0 top-0 z-10"></div>
      </div>
    </div>
  );
};
