import React, { FC, useEffect } from 'react';
import { NODE_ENV } from '../../../config/env';
import { SplashScreen } from './SplashScreen';

const disabled = !NODE_ENV || NODE_ENV === 'development';

/**
 * HOC to provide a splashscreen to the wrapped component
 * @param {React.ComponentType} WrappedComponent The component to wrap
 */
export const withSplashscreen = (WrappedComponent: React.ComponentType) => {
  const HOC: FC = () => {
    //#region Add event listener or show splash screen if page load is already complete
    useEffect(() => {
      if (document.readyState === 'complete') {
        showSplashScreenIfEnabled();
      }

      window.addEventListener('load', showSplashScreenIfEnabled);
      return () => {
        document.removeEventListener('load', showSplashScreenIfEnabled);
      };
    });
    //#endregion

    const showSplashScreenIfEnabled = () => {
      document.body.style.overflow = 'auto'; // enable scrollbars

      if (!disabled) {
        document.body.style.overflow = 'hidden'; // disable scrollbars

        const splashScreenDOMElement = document.getElementById('splash-screen');
        if (splashScreenDOMElement && splashScreenDOMElement.classList) {
          splashScreenDOMElement.classList.add('splashscreen-fadeout');

          setTimeout(() => {
            splashScreenDOMElement.classList.add('loaded');
            document.body.style.overflow = 'auto'; // enable scrollbars

            setTimeout(() => {
              splashScreenDOMElement.classList.add('hidden');
            }, 1400);
          }, 1400);
        }
      }
    };

    if (disabled) {
      return <WrappedComponent />;
    } else {
      return (
        <>
          <SplashScreen />
          <WrappedComponent />
        </>
      );
    }
  };
  return HOC;
};
