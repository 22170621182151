import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
// import { NODE_ENV } from './env';

i18n
  .use(backend) // load translation from `/public/locales`
  .use(languageDetector) // detect browser language
  .use(initReactI18next) // init i18next
  .init({
    fallbackLng: 'de',
    debug: false, // NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      wait: true,
      useSuspense: false,
    },
    ns: ['translation', 'seo'],
  });

export default i18n;
